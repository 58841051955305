/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  width: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.landing-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 60vh;
  width: 100%;
}

.logo-container {
  margin-bottom: 0;
  padding-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.kama-logo {
  max-width: 300px;
  height: auto;
  filter: invert(1);
}

.social-icons {
  display: flex;
  gap: 30px;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
}

.icon {
  font-size: 30px;
  color: #ffffff;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #999;
}

.hero {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  color: white;
  padding: 1rem;
}

.hero h1 {
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: 1rem;
  text-align: center;
}

.hero p {
  font-size: clamp(1rem, 3vw, 1.5rem);
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
}

/* Navbar responsive styles */
.navbar {
  background-color: #333;
  padding: 1rem;
  width: 100%;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 0.5rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
    align-items: center;
  }
  
  .navbar li {
    width: 100%;
    text-align: center;
  }
  
  .navbar a {
    display: block;
    padding: 0.5rem;
  }
}

/* Add this if you have sections with content */
.section {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* If you have any content containers, add this */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}
